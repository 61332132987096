import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem

} from 'reactstrap';
import socketIO from 'socket.io-client';
import moment from 'moment';
import logo from '../assets/svg/logo.svg';
import signal from '../assets/svg/signal.svg';
import noSignal from '../assets/svg/no-signal.svg';

import home from '../assets/svg/home.svg';
import devices from '../assets/svg/devices.svg';
import user from '../assets/svg/user.svg';
import log from '../assets/svg/log.svg';
import logout from '../assets/svg/logout.svg';
import secondarylogo from '../assets/svg/secondarylogo.svg';
import administrator from '../assets/svg/administrator.svg';
import cityIcon from '../assets/svg/header-icons/city.svg';
import postOfficeIcon from '../assets/svg/header-icons/postoffice.svg';
import rewardIcon from '../assets/svg/header-icons/reward.svg';
import userIcon from '../assets/svg/header-icons/userpeople.svg';
import singOutIcon from '../assets/svg/header-icons/signout.svg';

moment.locale('sr');

import { API_ENDPOINT } from '../constants';

export class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }



    render() {
        if (!this.props.uData) {
            return null;
        }
        return (
            <header>
                <Container>
                    <Row>
                        <Col lg="12" className="top">
                            <div className="secondary-logo">
                                <Link to='/'><Isvg src={secondarylogo} /></Link>
                                <span> <Isvg src={administrator} /> Administrator </span>
                            </div>

                        </Col>
                        <Col lg="12">
                            <ul className="navigation">
                                <li className={this.props[0].location.pathname == '/' ? 'active' : ''}> <Link to='/'><Isvg src={home} /> Početna </Link> </li>
                                {/* <li className={this.props[0].location.pathname == '/cities' ? 'active' : ''}> <Link to='/cities'><Isvg src={cityIcon} /> Gradovi </Link> </li> */}
                                <li className={this.props[0].location.pathname == '/prize' ? 'active' : ''}> <Link to='/prize'><Isvg src={rewardIcon} /> Nagrade </Link> </li>
                                <li className={this.props[0].location.pathname == '/prizePostOffice' ? 'active' : ''}> <Link to='/prizePostOffice'><Isvg src={userIcon} /> Dobitnici </Link> </li>
                                <li onClick={() => this.props.signOut()}> <Isvg src={singOutIcon} /> Izloguj se  </li>

                            </ul>
                        </Col>
                    </Row>
                </Container>
            </header>
        )
    }
}

export default Header;