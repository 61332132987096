import React, { Component } from 'react';
import Page from '../containers/page';

import {
    Container,
    Row,
    Col, Modal, ModalHeader, ModalFooter, ModalBody
} from 'reactstrap';

import Isvg from 'react-inlinesvg';

import FormBuilder from '../components/forms/modalFormBuilder';
import { required } from '../components/forms/validation';

import ListBuilder from '../components/listBuilder';
import DeleteModal from '../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../helpers/linkHelper';
import { API_ENDPOINT } from '../constants';

import signalIcon from '../assets/svg/signal.svg';
import editIcon from '../assets/svg/edit.svg';
import copyIcon from '../assets/svg/copy.svg';
import deleteIcon from '../assets/svg/delete.svg';
import open from '../assets/svg/open.svg';
import HTML from '../components/forms/fields/html';
import redTruck from '../assets/svg/header-icons/red-truck.svg';
import blackTruck from '../assets/svg/header-icons/black-truck.svg';
import logo from '../assets/svg/secondarylogo.svg';
import * as htmltoimage from 'html-to-image';
import textDecoration from '../assets/linije_2.svg';



class Users extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            areas: [],
            total: 0,
            loading: true,
            postOffice: [],
            modal: false,
            loadCsv: false,
        };
    }


    get = () => {
        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0, }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0, }, name, value, restart));
        }
    }


    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }

    update = (data) => {
        // fetch(API_ENDPOINT + '/data/prizePostOffice/' + data._id, {
        //     method: 'PUT',
        //     headers: {
        //         'content-type': 'application/json',
        //         'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,

        //     },
        //     body: JSON.stringify(data)
        // }).then(res => res.json()).then((result) => {
        //     this.get();
        //     this.setState({
        //         form: null
        //     })
        // })


    }

    htmlToImageConvert = async () => {
        let parentNode = document.getElementById('confirmation')
        parentNode.style.visibility = "unset"
        parentNode.style.zIndex = -1
        let imageNode = document.getElementById('image');
        if (imageNode) {
            imageNode.style.backgroundColor = '#e31e2a'
            this.setState({ loading: true });
            setTimeout(async () => {
                htmltoimage.toPng(imageNode)
                    .then((dataUrl) => {
                        // let base64str = dataUrl.substring(dataUrl.indexOf(',') + 1)
                        // var decoded = atob(base64str);

                        // console.log("FileSize: " + decoded.length);
                        // if(decoded.length < 90000){
                        //     this.htmlToImageConvert();
                        //     return
                        // }
                        const link = document.createElement("a");
                        link.download = `potvrda_${this.state.confirmData?.prizeNumber}.png`;
                        link.href = dataUrl;
                        link.click();
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                    .finally(
                        this.setState({ loading: false })
                    )
            }, 1000)

        }
    };

    insertOrUpdate = (data) => {
        this.setState({
            loading: true
        }, () => {
            if (!this.state.form._id) {

                fetch(API_ENDPOINT + '/data/prizePostOffice/new', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,

                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    this.get();
                    this.setState({
                        form: null
                    })
                })
            } else {
                fetch(API_ENDPOINT + '/data/prizePostOffice/' + this.state.form._id, {
                    method: 'PUT',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,

                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    this.get();
                    this.setState({
                        form: null
                    })
                })
            }
        })
    }
    generateCsv = () => {
        this.setState({
            loadCsv: true
        })
        fetch(API_ENDPOINT + '/data/export', {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,

            },
        }).then(res => res.json()).then((result) => {
            this.setState({
                exportedCsv: API_ENDPOINT + result.path
            })
        })
    }
    generateCsvAll = () => {
        this.setState({
            loadCsv: true
        })
        fetch(API_ENDPOINT + '/data/export/all', {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,

            },
        }).then(res => res.json()).then((result) => {
            this.setState({
                exportedCsvAll: API_ENDPOINT + result.path
            })
        })
    }


    delete = (id) => {
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/data/prizePostOffice/' + id, {
                method: 'DELETE',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,

                },
            }).then(res => res.json()).then((result) => {
                this.get();
            })
        })

    }

    print = () => {
        let html = ''
        for (let i = 0; i < this.state.items.length; i++) {
            html += `
            <div style="width: 45%; float: left; border: 1px dashed #000; padding: 10px">
            <div style="border: 1px solid #000; padding: 10px">
            <img src=${logo} style="width: 100px; "/>
            <table style="width: 100%; border: 0 !important;">
                <tr>
                    <td style="border: 0 !important">Korisnik:</td>
                    <td style="border: 0 !important">${this.state.items[i].firstName} ${this.state.items[i].lastName}</td>
                </tr>
                <tr>
                    <td style="border: 0 !important">Telefon:</td>
                    <td style="border: 0 !important">${this.state.items[i].phone}</td>
                </tr>
                <tr>
                    <td style="border: 0 !important">Email:</td>
                    <td style="border: 0 !important">${this.state.items[i].email}</td>
                </tr>
                <tr>
                    <td style="border: 0 !important">Broj nagrade:</td>
                    <td style="border: 0 !important">${this.state.items[i].prizeNumber}</td>
                </tr>
                <tr>
                    <td style="border: 0 !important">Tip nagrade:</td>
                    <td style="border: 0 !important">${this.state.items[i].prizeType}</td>
                </tr>
                <tr>
                    <td style="border: 0 !important">Grad:</td>
                    <td style="border: 0 !important">${this.state.items[i].city}</td>
                </tr>
                <tr>
                    <td style="border: 0 !important">Broj pošte:</td>
                    <td style="border: 0 !important">${this.state.items[i].postNumber}</td>
                </tr>
            </table>
            </div>
            </div>`
        }
        return html
    }


    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0, })
        }
        return (
            <div className="page">

                <Container>
                    <Row>
                        <Col lg="12">

                            <div className="panel">

                                <ListBuilder
                                    title={<>Lista nagrada za slanje
                                        {/* <button onClick={() => {
                                            this.setState({
                                             modal: !this.state.modal
                                        })
                                        }}>Štampaj</button> */}
                                        {
                                            this.state.loadCsv ?
                                                null
                                                :
                                                <button onClick={this.generateCsv}>Kreiraj CSV-export tekuce nedelje</button>
                                        }
                                        {
                                            this.state.exportedCsv ? <a href={this.state.exportedCsv} > <a><button>Preuzmite CSV-export tekuce nedelje</button></a> </a>
                                                :
                                                null
                                        }
                                        {
                                            this.state.loadCsv ?
                                                null
                                                :
                                                <button onClick={this.generateCsvAll}>Kreiraj CSV-export kompletne kampanje</button>
                                        }
                                        {
                                            this.state.exportedCsvAll ? <a href={this.state.exportedCsvAll} > <a><button>Preuzmite CSV-export kompletne kampanje</button></a> </a>
                                                :
                                                null
                                        }
                                    </>}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}

                                    fields={[
                                        { type: 'text', label: 'Ime', name: 'firstName', sortLabel: 'Po imenu', allowSort: true },
                                        { type: 'text', label: 'Prezime', name: 'lastName', sortLabel: 'Po imenu', allowSort: true },
                                        { type: 'text', label: 'Nagrada', name: 'prizeType', sortLabel: 'Po imenu', allowSort: true },
                                        //{ type: 'text', label: 'Broj lične karte', name: 'IDnumber', sortLabel: 'Po imenu', allowSort: true },
                                        //{ type: 'text', label: 'Telefon', name: 'phone', sortLabel: 'Po imenu', allowSort: true },
                                        //{ type: 'text', label: 'Email', name: 'email', sortLabel: 'Po imenu', allowSort: true },
                                        //{ type: 'text', name: label: 'Broj nagrade', 'prizeNumber', sortLabel: 'Po imenu', allowSort: true },
                                        //{ type: 'text', label: 'Naziv grada', name: 'city', sortLabel: 'Po imenu', allowSort: true },
                                        //{ type: 'text', label: 'poštanski broj', name: 'postOfficeNumber', sortLabel: 'Po imenu', allowSort: true },
                                    ]}
                                    rawItems={this.state.items}
                                    items={
                                        this.state.items.map((item, idx) => {
                                            return {
                                                ...item,
                                                name: item.name
                                            }

                                        })}
                                    // select={{
                                    //     name: 'query-postOffice',
                                    //     values: [{ name: 'Izaberite postanski broj', value: 0 }, ...this.state.postOffice.map((item) => { return { name: item.postanskiBroj, value: item._id } })]
                                    // }}
                                    actions={
                                        [
                                            {
                                                type: 'functionAction',
                                                render: (item) => <button style={{ backgroundColor: '#DFD54B', borderRadius: '5px', padding: '5px', color: 'black', border: 'none' }}
                                                    onClick={
                                                        () => {
                                                            this.setState({
                                                                confirmData: item
                                                            }, () => {
                                                                this.htmlToImageConvert();
                                                            })
                                                            
                                                        }
                                                    }
                                                >Preuzmi potvrdu</button>
                                            },


                                        ]
                                    }

                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>

                                {this.state.modal ?
                                    <Modal size='xl' isOpen={this.state.modal} toggle={() => { this.setState({ modal: !this.state.modal }) }} >
                                        <ModalHeader toggle={() => { this.setState({ modal: !this.state.modal }) }}></ModalHeader>
                                        <ModalBody>
                                            <HTML value={this.print()} onChange={() => { }}></HTML>
                                        </ModalBody>

                                    </Modal>
                                    : null}


                            </div>

                        </Col>
                    </Row>

                </Container>

                <div className='generate-image-element' id='confirmation'>
                    <div className='confirmation' id="image">
                        <div className='confirmation-header'>
                            <h1>
                                Potvrda o
                                <br />
                                poručenom poklonu
                            </h1>
                            <h3>
                                Uneli ste sledeće podatke:
                            </h3>
                        </div>
                        <div className='confirmation-content'>
                            <p>Ime: {this.state.confirmData?.firstName}</p>
                            <p>Prezime: {this.state.confirmData?.lastName}</p>
                            <p>ID broj nagrade: {this.state.confirmData?.prizeNumber}</p>
                            <p>Br. telefona: {this.state.confirmData?.phone}</p>
                            <p>E-mail adresa: {this.state.confirmData?.email}</p>

                            {this.state.confirmData?.idCard && <p>Br. lične karte/Pasoša: {this.state.confirmData?.idCard}</p>}
                            {this.state.confirmData?.address && <p>Adresa za isporuku nagrade: {this.state.confirmData?.address}</p>}
                            {this.state.confirmData?.zipCode && <p>Poštanski broj adrese za isporuku: {this.state.confirmData?.zipCode}</p>}
                            {this.state.confirmData?.size && <p>Veličina dresa: {this.state.confirmData?.size}</p>}
                            {this.state.confirmData?.ssn && <p>JMBG: {this.state.confirmData?.ssn}</p>}
                        </div>
                        <div className='confirmation-description'>
                            <div>
                                <p>VAŽNA NAPOMENA</p>
                                <p>
                                    Potrebno je da do <span>03.09.2024.</span> godine čuvate dobitne čepove boca ili etikete sa dobitnim kodovima, radi eventualne kontrole validnosti.
                                    <br />
                                    Sve nagrade izuzev vaučera za Glovo će biti isporučene na kućnu adresu, odnosno, na adresu unetu
                                    u podatke, najkasnije 60 dana nakon unošenja podataka, odnosno, poručivanja poklona na
                                    <span> porucipoklon.rs.</span>
                                </p>
                            </div>
                            <p>
                                Ukoliko lice čiji su podaci dostavijeni ne bude prisutno na adresi, pošiljka će biti vraćena u neku od
                                objekata „Pošta Srbije", takođe, biće dostavijen izveštaj o prispeću pošiljke sa informacijom gde se može preuzeti poklon u narednih 5 dana. Nakon isteka tih 5 dana, dobitnik gubi pravo na nagradu.
                            </p>
                            <p>
                                U slučaju da ste osvojili Glovo vaučer, u roku od 15 dana će na Vaš email stići email sa instrukcijama za korišćenje Glovo vaučera od <span>5.000 RSD.</span>
                            </p>
                        </div>
                        <img className='text-decoration' src={textDecoration} />
                    </div>
                </div>
            </div>
        );
    }
}

export default Page(Users);