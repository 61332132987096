import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'

import BootstrapField from './field';
import OptionsField from './options';
import HTMLField from './html';
import Image from './image';
import Tags from './tags';
import EasySelect from './easySelect';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

const renderTagsField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

        <Tags
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            {...input}
        />
    )


export const renderEasySelect = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type,
    children,
    haveValidation,
    optionValue,
    afterText,
    beforeText,
    multiple,
    disabled,
    values
}) => (

        <EasySelect
            placeholder={placeholder}
            errorText={touched && error}
            label={label}
            error={touched && error}
            type={type}
            haveValidation={haveValidation}
            optionValue={optionValue}
            afterText={afterText}
            beforeText={beforeText}
            multiple={multiple}
            disabled={disabled}
            values={values}
            {...input}

        >{children}</EasySelect>
    )


export const renderField = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type,
    children,
    haveValidation,
    optionValue,
    afterText,
    beforeText,
    multiple,
    disabled
}) => (

        <BootstrapField
            placeholder={placeholder}
            errorText={touched && error}
            label={label}
            error={touched && error}
            type={type}
            haveValidation={haveValidation}
            optionValue={optionValue}
            afterText={afterText}
            beforeText={beforeText}
            multiple={multiple}
            disabled={disabled}
            {...input}

        >{children}</BootstrapField>
    )

export const renderOptionsField = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type,
    haveValidation,
    disabled,
    values
}) => (

        <OptionsField
            placeholder={placeholder}
            errorText={touched && error}
            label={label}
            error={touched && error}
            type={type}
            haveValidation={haveValidation}
            disabled={disabled}
            values={values}
            {...input}

        ></OptionsField>
    )

export const renderHTMLField = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type,
    haveValidation,
}) => (

        <HTMLField
            placeholder={placeholder}
            errorText={touched && error}
            label={label}
            error={touched && error}
            type={type}
            haveValidation={haveValidation}
            {...input}

        ></HTMLField>
    )


export const renderImageField = ({
    input,
    label,
    height,
    meta: { touched, error },
    hideInfo
}) => (

        <Image
            label={label}
            errorText={touched && error}
            error={touched && error}
            height={height}
            hideInfo={hideInfo}
            {...input}
        />
    )


export const renderFields = (fields) => {
    return fields && fields.map((item, idx) => {
        if (item.type == 'col') {
            return (
                <Col lg={item.width.lg} sm={item.width.sm} xs={item.width.xs}>
                    {item.children && item.children.length ? renderFields(item.children) : null}
                </Col>
            )
        } else if (item.type == 'row') {
            return (
                <Row>
                    {item.children && item.children.length ? renderFields(item.children) : null}
                </Row>
            )
        } else if (item.type == 'options') {
            return (
                <Field
                    name={item.name}
                    component={renderOptionsField}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    disabled={item.disabled}
                    values={item.values}
                ></Field>

            )
        } else if (item.type == 'html') {
            return (
                <Field
                    name={item.name}
                    label={item.label}
                    component={renderHTMLField}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                ></Field>

            )
        } else if (item.type == 'image') {
            return (
                <Field
                    name={item.name}
                    label={item.label}
                    height={item.height}
                    hideInfo={item.hideInfo}
                    component={renderImageField}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                ></Field>

            )
        } else if (item.type == 'tags') {
            return (
                <Field
                    name={item.name}
                    label={item.label}
                    component={renderTagsField}
                    validate={item.validate}
                    haveValidation={item.validate && item.validate.length ? true : false}
                ></Field>

            )
        } else if (item.type == 'easy-select') {
            return (
                <Field
                    name={item.name}
                    component={renderEasySelect}
                    label={item.label}
                    placeholder={item.placeholder}
                    validate={item.validate}
                    type={'select'}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    multiple={item.multiple}
                    disabled={item.disabled}
                    values={item.values}
                >
                    
                </Field>
            )
        } else {
            return (
                <Field
                    name={item.name}
                    component={renderField}
                    label={item.label}
                    placeholder={item.placeholder}
                    validate={item.validate}
                    type={item.type}
                    haveValidation={item.validate && item.validate.length ? true : false}
                    optionValue={item.optionValue}
                    afterText={item.afterText}
                    beforeText={item.beforeText}
                    multiple={item.multiple}
                    disabled={item.disabled}
                >
                    {item.values && item.values.length ? item.values.map((option, oidx) => {
                        return (
                            <option key={oidx} value={option.value}>{option.name}</option>
                        )
                    })
                        :
                        null
                    }
                </Field>
            )
        }

    })
}
