import React, { Component } from 'react';
import Link from '../components/link';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';


import FormBuilder from '../components/forms/formBuilder';
import { required } from '../components/forms/validation';
import { API_ENDPOINT } from '../constants';

import rfid from '../assets/svg/rfid.svg';
import anim1 from '../assets/svg/anim1.svg';
import anim2 from '../assets/svg/anim2.svg';
import anim3 from '../assets/svg/anim3.svg';
import logo from '../assets/svg/logo.svg';
import mainlogo from '../assets/svg/main-logo.svg'


class LoginPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            promotedProjects: [],
            ...props.initialData,
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }
    }


    login = (data) => {
        fetch(API_ENDPOINT + '/auth/login', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.setState({
                    error: result.error
                })
            } else {
                localStorage.setItem('authToken', result.token);
                this.props.verifyUser();
                this.props[0].history.push('/')
            }
        })

    }


    render() {
        return (
            <div className="login-page">

                <Container>
                    <Row>
                       
                        <Col lg="6" className="right">
                            <div class="logo">
                               <Isvg src={mainlogo} />
                                <h2>Pristup administraciji</h2>
                                <p>Ulogujte se sa Vašim administratorskim nalogom.</p>
                            </div>
                            <FormBuilder
                                onSubmit={(data) => {
                                    this.login(data)
                                }}
                                buttonText={'PRIJAVI SE'}
                                fields={[
                                    {
                                        type: 'row',
                                        children: [

                                            {
                                                type: 'col',
                                                width: {
                                                    lg: 12,
                                                    sm: 12,
                                                    xs: 12
                                                },
                                                children: [
                                                    {
                                                        type: 'text',
                                                        name: 'username',
                                                        label: 'Administrator',
                                                        placeholder: 'Unesite korisničko ime...',
                                                        validate: [required('Korisničko ime je obavezno!')]
                                                    },
                                                ]
                                            },
                                            {
                                                type: 'col',
                                                width: {
                                                    lg: 12,
                                                    sm: 12,
                                                    xs: 12
                                                },
                                                children: [
                                                    {
                                                        type: 'password',
                                                        name: 'password',
                                                        label: 'Šifra',
                                                        placeholder: 'Unesite lozinku',

                                                        validate: [required('Lozinka je obavezna!')]
                                                    },
                                                ]
                                            },

                                        ]
                                    }
                                ]}
                            ></FormBuilder>
                            <p className="error-message">{this.state.error}</p>
                                
                        </Col>

                        <span className="copyright">
                               2020 The Coca-Cola company. "Coca-cola", "Red Disk" i "To je taj osjećaj" su zaštitni znaci The Coca Cola Company.
                            </span>
                       
            
                    </Row>
                </Container>

            </div>
        );
    }
}

export default Page(LoginPage);