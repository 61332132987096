import React, { Component } from 'react';
import Link from '../components/link';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import {
    Container,
    Row,
    Col,
    Input
} from 'reactstrap';


import FormBuilder from '../components/forms/modalFormBuilder';
import { required } from '../components/forms/validation';

import ListBuilder from '../components/listBuilder';
import DeleteModal from '../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../helpers/linkHelper';

import info from '../assets/svg/info.svg';
import { Line, Bar } from 'react-chartjs-2';
import icon from '../assets/svg/icon.svg';

const data = {
    labels: ['02 Jan', '03 Jan', '04 Jan', '05 Jan', '06 Jan', '07 Jan'],
    datasets: [
        {
            label: 'PREGLED ARTIKALA PO DANIMA',
            fill: false,
            lineTension: 0.4,
            backgroundColor: '#F4F5FB',
            borderColor: '#DFD54B',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#DFD54B',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#DFD54B',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [65, 59, 80, 81, 56, 55]
        }
    ]
};

const barData = {
    labels: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23',],
    datasets: [
        {
            label: 'My First dataset',
            backgroundColor: '#1E252D',
            borderColor: '#1E252D',
            borderWidth: 1,
            data: [65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 100, 65, 59]
        }
    ]
};

var striptags = require('striptags');


class HomePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            promotedProjects: [],
            ...props.initialData,
            form: false,

            useState: false,
            bannerForm: false,
            params: {

            },
            areas: [],
            items: {},
        };
    }
    get = () => {
        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
        this.get();


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }



        window.Chart.elements.Rectangle.prototype.draw = function () {

            var ctx = this._chart.ctx;
            var vm = this._view;
            var left, right, top, bottom, signX, signY, borderSkipped, radius;
            var borderWidth = vm.borderWidth;
            // Set Radius Here
            // If radius is large enough to cause drawing errors a max radius is imposed
            var cornerRadius = 15;

            if (!vm.horizontal) {
                // bar
                left = vm.x - vm.width / 2;
                right = vm.x + vm.width / 2;
                top = vm.y;
                bottom = vm.base;
                signX = 1;
                signY = bottom > top ? 1 : -1;
                borderSkipped = vm.borderSkipped || 'bottom';
            } else {
                // horizontal bar
                left = vm.base;
                right = vm.x;
                top = vm.y - vm.height / 2;
                bottom = vm.y + vm.height / 2;
                signX = right > left ? 1 : -1;
                signY = 1;
                borderSkipped = vm.borderSkipped || 'left';
            }

            // Canvas doesn't allow us to stroke inside the width so we can
            // adjust the sizes to fit if we're setting a stroke on the line
            if (borderWidth) {
                // borderWidth shold be less than bar width and bar height.
                var barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom));
                borderWidth = borderWidth > barSize ? barSize : borderWidth;
                var halfStroke = borderWidth / 2;
                // Adjust borderWidth when bar top position is near vm.base(zero).
                var borderLeft = left + (borderSkipped !== 'left' ? halfStroke * signX : 0);
                var borderRight = right + (borderSkipped !== 'right' ? -halfStroke * signX : 0);
                var borderTop = top + (borderSkipped !== 'top' ? halfStroke * signY : 0);
                var borderBottom = bottom + (borderSkipped !== 'bottom' ? -halfStroke * signY : 0);
                // not become a vertical line?
                if (borderLeft !== borderRight) {
                    top = borderTop;
                    bottom = borderBottom;
                }
                // not become a horizontal line?
                if (borderTop !== borderBottom) {
                    left = borderLeft;
                    right = borderRight;
                }
            }

            ctx.beginPath();
            ctx.fillStyle = '#e12828';
            ctx.strokeStyle = 'white';
            ctx.lineWidth = borderWidth;

            // Corner points, from bottom-left to bottom-right clockwise
            // | 1 2 |
            // | 0 3 |
            var corners = [
                [left, bottom],
                [left, top],
                [right, top],
                [right, bottom]
            ];

            // Find first (starting) corner with fallback to 'bottom'
            var borders = ['bottom', 'left', 'top', 'right'];
            var startCorner = borders.indexOf(borderSkipped, 0);
            if (startCorner === -1) {
                startCorner = 0;
            }

            function cornerAt(index) {
                return corners[(startCorner + index) % 4];
            }

            // Draw rectangle from 'startCorner'
            var corner = cornerAt(0);
            ctx.moveTo(corner[0], corner[1]);

            let nextCornerId = 0;
            let nextCorner;


            for (var i = 1; i < 4; i++) {
                corner = cornerAt(i);
                nextCornerId = i + 1;
                if (nextCornerId == 4) {
                    nextCornerId = 0
                }

                nextCorner = cornerAt(nextCornerId);

                let width = corners[2][0] - corners[1][0];
                let height = corners[0][1] - corners[1][1];
                let x = corners[1][0];
                let y = corners[1][1];

                var radius = cornerRadius;

                // Fix radius being too large
                if (radius > height / 2) {
                    radius = height / 2;
                } if (radius > width / 2) {
                    radius = width / 2;
                }

                ctx.moveTo(x + radius, y);
                ctx.lineTo(x + width - radius, y);
                ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
                ctx.lineTo(x + width, y + height - radius);
                ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
                ctx.lineTo(x + radius, y + height);
                ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
                ctx.lineTo(x, y + radius);
                ctx.quadraticCurveTo(x, y, x + radius, y);




            }

            ctx.fill();



            ctx.beginPath();
            ctx.fillStyle = vm.backgroundColor;
            ctx.lineWidth = borderWidth;

            // Corner points, from bottom-left to bottom-right clockwise
            // | 1 2 |
            // | 0 3 |
            var corners = [
                [left, bottom],
                [left, top],
                [right, top],
                [right, bottom]
            ];

            // Find first (starting) corner with fallback to 'bottom'
            var borders = ['bottom', 'left', 'top', 'right'];
            var startCorner = borders.indexOf(borderSkipped, 0);
            if (startCorner === -1) {
                startCorner = 0;
            }

            // Draw rectangle from 'startCorner'
            var corner = cornerAt(0);
            ctx.moveTo(corner[0], corner[1]);

            nextCornerId = 0;
            nextCorner;


            for (var i = 1; i < 4; i++) {
                corner = cornerAt(i);
                nextCornerId = i + 1;
                if (nextCornerId == 4) {
                    nextCornerId = 0
                }

                nextCorner = cornerAt(nextCornerId);

                let width = corners[2][0] - corners[1][0];
                let height = corners[0][1] - corners[1][1];
                let x = corners[1][0];
                let y = corners[1][1] + 10;

                var radius = cornerRadius;

                // Fix radius being too large
                if (radius > height / 2) {
                    radius = height / 2;
                } if (radius > width / 2) {
                    radius = width / 2;
                }

                ctx.moveTo(x + radius, y);
                ctx.lineTo(x + width - radius, y);
                ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
                ctx.lineTo(x + width, y + height - radius);
                ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
                ctx.lineTo(x + radius, y + height);
                ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
                ctx.lineTo(x, y + radius);
                ctx.quadraticCurveTo(x, y, x + radius, y);




            }

            ctx.fill();

        };

    }



    toggle = () => {
        this.setState({
            form: !this.state.form
        })
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, {  }, name, value, restart));
        }
    }

    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { }, fields, restart));
        }
    }

    render() {
        let params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        // console.log(params);
        return (
            <div className="page">

                <Container className="dashboard">
                    
                    <Row>
                        <Col lg="12" className="top">
                            <h2>Dashboard</h2>
                            


                        </Col>
                        <Col lg="6">
                            <div className="block">
                                <h3><Isvg src={icon} /> Informacije o sistemu</h3>
                                <ul>
                                    <li><span>Ukupno nagrada:</span><span>{this.state.totalNumberOfPrizes}</span> </li>
                                    <li><span>Ukupno poručenih nagrada:</span><span>{this.state.usedPrizes}</span> </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="block">
                                <h3><Isvg src={icon} /> Informacije o nagradama</h3>
                                <ul>
                                    <li><span>Ukupno poručenih ovaj mesec:</span><span>{this.state.inThisMonth}</span> </li>
                                    <li><span>&nbsp;</span> </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg="12">
                            <div className="chart">
                                <h6>PRIJAVA NAGRADA PO DANIMA</h6>

                                {this.state.logPerDays ?

                                    <Bar data={
                                        {
                                            labels:this.state.logPerDays.map(item => item.day) ,
                                            datasets: [
                                                {
                                                    label: 'Broj logovanja',
                                                    backgroundColor: '#1E252D',
                                                    borderColor: '#1E252D',
                                                    borderWidth: 1,
                                                    data: this.state.logPerDays.map(item => item.count) ,
                                                }
                                            ]
                                        
                                        }
                                    } options={{
                                        responsive: true,
                                        maintainAspectRatio: false,

                                        legend: {
                                            display: false,

                                        },

                                        scales: {
                                            xAxes: [{
                                                gridLines: {
                                                    color: "rgba(0, 0, 0, 0)",
                                                    zeroLineWidth: 0,
                                                    drawBorder: false,
                                                }
                                            }],
                                            yAxes: [{
                                                gridLines: {
                                                    color: "rgba(255, 255, 255, 1)",
                                                    lineWidth: 2,
                                                    drawBorder: false,

                                                }
                                            }]
                                        },
                                        hover: {
                                            duration: 0
                                        },
                                        "animation": {
                                            "duration": 500,
                                            "onComplete": function () {
                                                var chartInstance = this.chart,
                                                    ctx = chartInstance.ctx;

                                                ctx.font = window.Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, window.Chart.defaults.global.defaultFontStyle, window.Chart.defaults.global.defaultFontFamily);
                                                ctx.textAlign = 'center';
                                                ctx.textBaseline = 'bottom';
                                                ctx.fillStyle = '#fff';

                                                this.data.datasets.forEach(function (dataset, i) {
                                                    var meta = chartInstance.controller.getDatasetMeta(i);
                                                    meta.data.forEach(function (bar, index) {
                                                        var data = dataset.data[index];
                                                        if (window.innerWidth >= 768)
                                                            ctx.fillText(data, bar._model.x, bar._model.y + (320 - bar._model.y) / 2 + 10);
                                                    });
                                                });
                                            }
                                        },


                                    }} />
                                    :
                                    null
                                }
                            </div>

                        </Col>


                        {/*
                        <Col lg="6">
                            <div className="chart">
                                <h6>ONLINE UREĐAJI PO DANIMA</h6>
                                <Line data={data} options={{
                                    responsive: true,
                                    maintainAspectRatio: false,

                                    legend: {
                                        display: false,

                                    },

                                    scales: {
                                        xAxes: [{
                                            gridLines: {
                                                color: "rgba(0, 0, 0, 0)",
                                                zeroLineWidth: 0,
                                                drawBorder: false,
                                            }
                                        }],
                                        yAxes: [{
                                            gridLines: {
                                                color: "rgba(255, 255, 255, 1)",
                                                lineWidth: 2,
                                                drawBorder: false,

                                            }
                                        }]
                                    }

                                }} />

                            </div>

                        </Col>
                            */}
                    </Row>
                </Container>


            </div>
        );
    }
}

export default Page(HomePage);