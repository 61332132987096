import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Header from '../components/header';


export const DefaultLayout = (Wrapped) => (props) => {
    return (
        <div>
            {true || props.uData ? <Header {...props} /> : null } 

            <Wrapped {...props} />

        </div>
    );
};

export default DefaultLayout;