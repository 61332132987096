import React, { Component } from 'react';
import Page from '../containers/page';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

import Isvg from 'react-inlinesvg';

import FormBuilder from '../components/forms/modalFormBuilder';
import { required } from '../components/forms/validation';

import ListBuilder from '../components/listBuilder';
import DeleteModal from '../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../helpers/linkHelper';
import { API_ENDPOINT } from '../constants';

import signalIcon from '../assets/svg/signal.svg';
import editIcon from '../assets/svg/edit.svg';
import copyIcon from '../assets/svg/copy.svg';
import deleteIcon from '../assets/svg/delete.svg';


class Users extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            areas: [],
            total: 0,
            loading: true
        };
    }


    get = () => {
        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0,  })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0,  })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0,  }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0,  }, name, value, restart));
        }
    }


    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }


    insertOrUpdate = (data) => {
        this.setState({
            loading: true
        }, () => {
            if (!this.state.form._id) {

                fetch(API_ENDPOINT + '/data/cities/new', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,

                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    this.get();
                    this.setState({
                        form: null
                    })
                })
            } else {
                fetch(API_ENDPOINT + '/data/cities/' + this.state.form._id, {
                    method: 'PUT',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,

                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    this.get();
                    this.setState({
                        form: null
                    })
                })
            }
        })
    }

    delete = (id) => {
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/data/cities/' + id, {
                method: 'DELETE',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,

                },
            }).then(res => res.json()).then((result) => {
                this.get();
            })
        })

    }



    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0,  })
        }
        return (
            <div className="page">

                <Container>
                    <Row>
                        <Col lg="12">
                            <div className="panel">
                                <ListBuilder
                                    title="Lista gradova"
                                    /*
                                    button={{
                                        action: () => {this.setState({form: true})},
                                        text: 'Dodaj grad'
                                    }}
                                    */
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        //{ type: 'text', name: '_id', sortLabel: 'Po broju', allowSort: true },
                                        { type: 'text', label: 'Naziv grada', name: 'city', sortLabel: 'Po imenu', allowSort: true }
                                    ]}
                                    rawItems={this.state.items}
                                    items={
                                        this.state.items.map((item, idx) => {
                                            return {
                                                ...item,
                                                name: item.name
                                            }

                                        })}
                                    /*select={{
                                        name: 'areaId',
                                        values: [{ name: 'Izaberite područje', value: 0 }, ...this.state.areas.map((item) => { return { name: item.areaname, value: item.id } })]
                                    }}*/
                                    /*
                                    actions={
                                        [
                                            /*{
                                                component: <Isvg src={editIcon} />,
                                                onClick: (item) => {this.setState({form: item})}
                                            },
                                            */

                                            /*{
                                                component: <Isvg src={deleteIcon} />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }
                                    */
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                                <DeleteModal
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                                >
                                    Delete region <strong>{this.state.deleteModal && this.state.deleteModal.name}</strong>?
                                </DeleteModal>

                            </div>
                        </Col>
                    </Row>

                </Container>
                {this.state.form ?
                    <FormBuilder
                        onSubmit={(data) => this.insertOrUpdate(data)}
                        initialValues={this.state.form}
                        isOpen={this.state.form}
                        toggle={() => this.setState({ form: null })}
                        title={this.state.form.id ? 'Izmena grada' : 'Dodavanje grada'}
                        buttonText={'SNIMI IZMENE'}
                        fields={[
                            {
                                type: 'row',
                                children: [
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'name',
                                                label: 'Naziv grada',
                                                validate: [required('Naziv grada je obavezan!')]
                                            },
                                        ]
                                    },


                                ]
                            }
                        ]}
                    ></FormBuilder>
                    :
                    null
                }

            </div>
        );
    }
}

export default Page(Users);