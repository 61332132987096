import HomePage from './views/homePage';


import Login from './views/loginPage';

import { API_ENDPOINT } from './constants';



import Cities from './views/cities';
import postOffice from './views/postOffice';
import prize from './views/prize';
import prizePostOffice from './views/prizePostOffice';


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}



export const routes = [

    {
        path: "/",
        component: HomePage,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'CocaCola '.translate(lang),
                

            }
        },
        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                

                return fetchFunction(API_ENDPOINT + '/data/statistic', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        filter: query.filter,
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return result
                        
                })

            },
        ]

    },
    // {
    //     path: "/",
    //     component: prizePostOffice,
    //     loginNeeded: true,
    //     preAuthComponent: Login,
    //     generateSeoTags: (data, lang) => {
    //         return {
    //             title: 'CocaCola '.translate(lang),
                

    //         }
    //     },
    //     loadData: [

    //     ],
    //     loadDataWithQuery: [
    //         (fetchFunction, lang, match, query) => {
    //             let queryFields = {};
    //             for (var key in query) {
    //                 if (key && key.indexOf('query-') === 0 && query[key] != '0') {
    //                     queryFields[key.replace('query-', '')] = query[key];
    //                 }
    //             }

    //             return fetchFunction(API_ENDPOINT + '/data/prizePostOffice', {
    //                 method: 'POST',
    //                 headers: {
    //                     'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
    //                     'content-type': 'application/json'
    //                 },
    //                 body: JSON.stringify({
    //                     page: query.page,
    //                     entries: query.entries,
    //                     sortType: query.sortType,
    //                     sortField: query.sortField,
    //                     query: queryFields,
    //                     filter: query.filter,
    //                 })
    //             }).then(parseJSON).then(({ result, status }) => {
    //                 if (status >= 200 && status < 300)
    //                     return {
    //                         items: result.items,
    //                         total: result.total,
    //                     }
    //             })

    //         },
    //         (fetchFunction, lang, match, query) => {


    //             return fetchFunction(API_ENDPOINT + '/data/postOffice', {
    //                 method: 'GET',
    //                 headers: {
    //                     'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
    //                     'content-type': 'application/json'
    //                 },
                   
    //             }).then(parseJSON).then(({ result, status }) => {
    //                 if (status >= 200 && status < 300)
    //                     return {
    //                         postOffice: result
    //                     }
    //             })

    //         },
    //     ]
    // },
    {
        path: "/cities",
        component: Cities,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'CocaCola '.translate(lang),
                

            }
        },
        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/data/cities', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        filter: query.filter,
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            items: result.items,
                            total: result.total,
                        }
                })

            },
       
        ]
        

    },
    {
        path: "/prizePostOffice",
        component: prizePostOffice,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'CocaCola '.translate(lang),
                

            }
        },
        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0 && query[key] != '0') {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/prizePostOffice', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        filter: query.filter,
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            items: result.items,
                            total: result.total,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/data/postOffice', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                   
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            postOffice: result
                        }
                })

            },
        ]
        

    },
    {
        path: "/prize",
        component: prize,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'CocaCola '.translate(lang),
                

            }
        },
        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0 && query[key] != '0') {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/prize', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        filter: query.filter,
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            items: result.items,
                            total: result.total,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/data/postOffice', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                   
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            postOffice: result
                        }
                })

            },
        ]
        

    },
    
    {
        path: "/postOffice",
        component: postOffice,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'CocaCola '.translate(lang),
                

            }
        },
        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0 && query[key] != '0') {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/postOffice', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields,
                        filter: query.filter,
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            items: result.items,
                            total: result.total,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/data/cities', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                   
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            cities: result
                        }
                })

            },
       
        ]
        

    },

    {
        path: "/login",
        component: Login,
        redirectUser: '/',
        generateSeoTags: (data, lang) => {
            return {
                title: 'CocaCola '.translate(lang),
                

            }
        },
        loadData: [

        ]

    },


    

];

export function generateRoutes(r) {
    let newRoutes = [];
    for (let i = 0; i < r.length; i++) {
        newRoutes.push(r[i]);

        /*newRoutes.push({
            ...r[i],
            path: linkTranslates.en[r[i].path]
        })*/
    }
    return newRoutes;
}